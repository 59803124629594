import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Login from './app/pages/Login.jsx'
import Initiate from './app/pages/Initiate.jsx'
import RealmInfo from './app/pages/RealmInfo.jsx'
import SelectRealm from './app/pages/SelectRealm.jsx'
import RealmCredentialsLogin from './app/pages/RealmCredentialsLogin.jsx'
import ConsentInfo from './app/pages/ConsentInfo.jsx'
import ChangePassword from './app/pages/ChangePassword.jsx'
import {
  crendentialsViewPath,
  codeViewPath,
  newPassViewPath,
  changedPassViewPath,
  enterPassViewPath,
  successfulyChangedViewPath,
  passViewPath,
} from './app/pages/login/constants'

function AppRoutes() {
  const overrideAddress =
    process.env.NODE_ENV !== 'production'
      ? `&override_address=${window.location.protocol}//${window.location.host}`
      : ''
  const portalApp = 'portal'
  const webappApp = 'webapp'
  return (
    <Routes>
      <Route
        path='/'
        element={
          <Initiate selectedApp={webappApp} overrideAddress={overrideAddress} />
        }
      />
      <Route
        path='/webapp'
        element={
          <Initiate selectedApp={webappApp} overrideAddress={overrideAddress} />
        }
      />
      <Route
        path='/portal'
        element={
          <Initiate selectedApp={portalApp} overrideAddress={overrideAddress} />
        }
      />
      <Route
        path='/initiate'
        element={
          <Initiate selectedApp={'initiate'} overrideAddress={overrideAddress} />
        }
      />
      <Route path='/login/common' element={<Login />} />
      <Route path='/login/realm' element={<RealmInfo />} />
      <Route path='/login/select-realm' element={<SelectRealm />} />
      <Route
        path={passViewPath}
        element={<RealmCredentialsLogin showPage={passViewPath} />}
      />
      <Route
        path={crendentialsViewPath}
        element={<RealmCredentialsLogin showPage={crendentialsViewPath} />}
      />
      <Route
        path={codeViewPath}
        element={<RealmCredentialsLogin showPage={codeViewPath} />}
      />
      <Route
        path={newPassViewPath}
        element={<RealmCredentialsLogin showPage={newPassViewPath} />}
      />
      <Route
        path={changedPassViewPath}
        element={<RealmCredentialsLogin showPage={changedPassViewPath} />}
      />
      <Route
        path={enterPassViewPath}
        element={<ChangePassword showPage={enterPassViewPath} />}
      />
      <Route
        path={successfulyChangedViewPath}
        element={<ChangePassword showPage={successfulyChangedViewPath} />}
      />
      <Route path='/login/consent' element={<ConsentInfo />} />
      <Route
        path='/*'
        element={
          <Initiate selectedApp={webappApp} overrideAddress={overrideAddress} />
        }
      />
    </Routes>
  )
}

export default AppRoutes
